<template>
  <div>
    <div class="content">
      <div class="row">
        <div class="col">Showing Carts with at least one item.</div>
      </div>
      <div class="row" v-if="cartsReady">
        <div class="col">
          <div class="table-container">
            <table class="table align-middle">
              <thead class="head">
                <tr>
                  <th class="ps-4">Total Items</th>
                  <th>Unique Items</th>
                  <th>User</th>
                  <th class="text-end pe-5">Value</th>
                  <th>Last Updated</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="cart in cartsList.data" :key="cart.id">
                  <td class="ps-4">{{cart.total_items}}</td>
                  <td>{{cart.unique_items}}</td>
                  <td>{{cart.user_email}}</td>
                  <td class="text-end pe-5">
                    <span class="bold blue">{{formatValue(cart.total_value_in_cents, 'money')}}</span>
                  </td>
                  <td>
                    <span class="pale">{{formatValue(cart.updated_at, 'longDate')}} ({{formatValue(cart.updated_at, 'diffForHumans')}})</span>
                  </td>
                  <td>
                    <div class="d-flex">
                      <a class="vave-btn btn-small me-2" @click="setItems(cart.items), showOutputModal()" title="show items in the cart">
                        <i class="fa fa-eye"></i>
                      </a>
                      <a class="vave-btn btn-small" title="show tracking events" v-if="cart.sticky_id" :href="'/stickies/'+ cart.sticky_id + '/events'">
                        <i class="fa-solid fa-shoe-prints"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <pagination class="paginator" :limit="4" align="center" :data="cartsList" @pagination-change-page="getCarts"></pagination>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col">
          <div class="loading-placeholder">
            <div class="spinner-border spinner-border-sm ms-2 big-spinner light-spinner" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="background p-4">
            <apexchart v-if="chartReady" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
            <div v-else class="loading-placeholder">
              <div class="spinner-border spinner-border-sm ms-2 big-spinner light-spinner" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <widget-config-modal ref="outputModal">
      <template v-slot:title>Output</template>
      <template v-slot:body>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>name</th>
              <th>variation</th>
              <th>quantity</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item,k in items" :key="k">
              <td>{{item.name}}</td>
              <td>{{item.variation_name}}</td>
              <td>{{item.quantity}}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </widget-config-modal>
  </div>
</template>

<script>
import axios from "../http.js";
import pagination from "laravel-vue-pagination";
import WidgetConfigModal from "./widgets/WidgetConfigModal";
import { formatValue } from "./../formatters";
import { forEach } from "lodash";
import VueApexCharts from "vue-apexcharts";

export default {
  data() {
    return {
      chartReady: false,
      output: "",
      cartsList: {},
      cartsReady: false,
      items: [],
      series: [
        {
          name: "",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#77B6EA", "#545454"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Carts",
          align: "left",
        },

        markers: {
          size: 1,
        },
        xaxis: {
          categories: [],
          title: {
            text: "Day",
          },
        },
        yaxis: {
          title: {
            text: "# of Carts updated",
          },
          min: 0,
          max: 10,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
    };
  },
  components: { WidgetConfigModal, pagination, apexchart: VueApexCharts },
  mounted() {
    if (Object.keys(this.cartsList).length <= 0) {
      this.getCarts();
      this.getChartData();
    }
  },
  methods: {
    formatValue,
    showOutputModal() {
      this.$refs.outputModal.show();
    },
    setItems(items) {
      this.items = items;
    },
    getCarts(page = 1, perPage = 15) {
      this.cartsReady = false;
      this.output = "";
      this.cartsList = {};
      axios
        .get("/api/admin/carts?page=" + page + "&per_page=" + perPage)
        .then((response) => {
          if (response.data.data) {
            this.cartsList = response.data;
          }
        })
        .finally(() => {
          this.cartsReady = true;
        });
    },
    getChartData() {
      this.series = [{ name: "", data: [1, 4, 5, 6, 7, 8, 8] }];

      axios.get("/api/admin/carts/chart").then((response) => {
        if (response.data) {
          var keys = [];
          var values = [];

          forEach(response.data.data, (element, key) => {
            keys.push(key);
            values.push(element);
          });

          this.chartOptions.yaxis.max = response.data.max;
          this.chartOptions.xaxis.categories = keys.reverse();
          this.series = [{ name: "Updated Carts", data: values.reverse() }];
        }
        this.chartReady = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
}
tbody tr {
  &:hover {
    background: #fafafa;
  }
}
</style>